import React from 'react'

import {
  StyledBlueText,
  StyledConatnerReportByMonthsPDF,
  StyledContainerTableDetail,
  StyledGrayText,
  StyledTableFilters,
  StyledTHDetail
} from './styledComponent'

import {
  LandscapeOrientation,
  PDFHeaderContainer,
  TableHeadersTR
} from '../ReportComponentsPDF'
import {
  ReportDownloadFileByMonthDaysType,
  ListItems
} from '../../types/Reports/ReportDownloadFileByMonthDays'
import moment from 'moment'
import { convertNumberToMoney } from '../../utils/general'
import { getFromTitleDetail } from '../../containers/ReportContainerByMonths/utils'
import { PAYMENT_TYPES_SPANISH } from '../../utils/reports'

export const TableRowDetailsSummary: React.FC<ListItems> = ({
  date,
  ticketId,
  paymentType,
  totalAmount,
  products,
  time,
  name,
  posId
}) => {
  const arrayProducts = products.split(',')
  const [dateFirstSection] = date.split('T')
  const dateFormated = moment(dateFirstSection)
    .format('DD-MMM-YYYY')
    .toUpperCase()

  const keyTypes = paymentType.replaceAll(' ', '').toUpperCase()
  const paymentSpanish = PAYMENT_TYPES_SPANISH[keyTypes]
  const noData = totalAmount === 0
  return (
    <tr>
      <StyledTHDetail styles={{ isred: noData }}>{dateFormated}</StyledTHDetail>
      <StyledTHDetail styles={{ isred: noData }}># {ticketId}</StyledTHDetail>
      <StyledTHDetail styles={{ isred: noData }}>
        {noData ? '--' : moment(time, 'hh:mm:ss').format('h:mm:ss a')}
      </StyledTHDetail>
      <StyledTHDetail styles={{ isred: noData }}>
        {paymentSpanish || '--'}
      </StyledTHDetail>
      <StyledTHDetail styles={{ isred: noData }}>
        {convertNumberToMoney(totalAmount)}
      </StyledTHDetail>
      <StyledTHDetail styles={{ isred: noData }}>
        {name || '--'}{' '}
      </StyledTHDetail>
      <StyledTHDetail styles={{ isred: noData }}>
        {posId || '--'}
      </StyledTHDetail>
      <StyledTHDetail styles={{ isred: noData }}>
        {arrayProducts.map((product: string, index: number) => (
          <div key={index}>
            {product}
            {index !== arrayProducts.length - 1 && ','}
            <br />
          </div>
        ))}
      </StyledTHDetail>
    </tr>
  )
}

interface FilterHeaderProps {
  minPrice: string | undefined
  maxPrice: string | undefined
  paymentType: string | undefined
  collaborator: string | undefined
}
const FilterHeader: React.FC<FilterHeaderProps> = ({
  minPrice,
  maxPrice,
  paymentType,
  collaborator
}) => {
  const withNoFilter = !minPrice && !maxPrice && !paymentType && !collaborator

  const existsArangeFilter = !!minPrice || !!maxPrice
  const minPriceFormatted = convertNumberToMoney(Number(minPrice || 0))
  const maxPriceFormatted = maxPrice
    ? convertNumberToMoney(Number(maxPrice))
    : '$ - '
  const keyTypes = paymentType?.replaceAll(' ', '').toUpperCase() || ''

  return (
    <StyledTableFilters>
      <table>
        <thead>
          <tr></tr>
        </thead>
        <tbody>
          <tr>
            <StyledBlueText>Filtros aplicados</StyledBlueText>
          </tr>
          {withNoFilter ? (
            <tr>
              <StyledGrayText>No se aplicaron filtros</StyledGrayText>
            </tr>
          ) : (
            <tr>
              {existsArangeFilter && (
                <>
                  <StyledGrayText>Rango de precio:</StyledGrayText>
                  <StyledBlueText>{`${minPriceFormatted} - ${maxPriceFormatted}`}</StyledBlueText>
                </>
              )}

              {paymentType && (
                <>
                  <StyledGrayText>Tipo de pago:</StyledGrayText>
                  <StyledBlueText>
                    {PAYMENT_TYPES_SPANISH[keyTypes]}
                  </StyledBlueText>
                </>
              )}

              {collaborator && (
                <>
                  <StyledGrayText>Colaborador:</StyledGrayText>
                  <StyledBlueText>{collaborator}</StyledBlueText>
                </>
              )}
            </tr>
          )}
        </tbody>
      </table>
    </StyledTableFilters>
  )
}
interface propsType {
  storeInformation: {
    storeID: string | undefined
    storeName: string
    partnerName: string
  }
  queryData: ReportDownloadFileByMonthDaysType | null
  from: string | undefined
  to: string | undefined
  minPrice?: string | undefined
  maxPrice?: string | undefined
  paymentType?: string | undefined
  collaborator?: string | undefined
}

export const ReportByMonthDaysPDF = React.forwardRef(
  (
    props: propsType,
    ref?:
      | ((instance: HTMLDivElement | null) => void)
      | React.RefObject<HTMLDivElement>
      | null
      | undefined
  ) => {
    const {
      queryData,
      from,
      to,
      minPrice,
      maxPrice,
      paymentType,
      collaborator
    } = props

    const filters = { minPrice, maxPrice, paymentType, collaborator }

    if (!queryData) return null
    const { getTotalSaleByDateReport: data } = queryData
    const labelHeader: string[] = [
      'FECHA',
      'TICKET',
      'HORA',
      'TIPO DE PAGO',
      'MONTO',
      'NOMBRE DE USUARIO',
      'POS',
      'PRODUCTOS'
    ]
    const fromFormated = from?.replaceAll('-', '')
    const toFormated = to?.replaceAll('-', '')

    return (
      <StyledConatnerReportByMonthsPDF ref={ref}>
        <LandscapeOrientation />
        <div>
          <PDFHeaderContainer
            storeID={props.storeInformation.storeID}
            storeName={props.storeInformation.storeName}
            partnerName={props.storeInformation.partnerName}
            title={`REPORTE DE VENTAS ${getFromTitleDetail(
              fromFormated,
              toFormated
            ).toUpperCase()}
              `}
          />

          <FilterHeader {...filters} />

          <StyledContainerTableDetail>
            <table>
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                <TableHeadersTR labelHeader={labelHeader} colSpan={1} />
                {data.ListItems.map((dataRow, index) => (
                  <TableRowDetailsSummary {...dataRow} key={index} />
                ))}
              </tbody>
            </table>
          </StyledContainerTableDetail>
        </div>
      </StyledConatnerReportByMonthsPDF>
    )
  }
)
