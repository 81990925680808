import moment from 'moment'

import {
  ReportDownloadFileByMonthDaysType,
  ListItems
} from '../../types/Reports/ReportDownloadFileByMonthDays'

import { CollaboratorInterface } from '../../types/WorkerType'

import { customEventGA } from '../../utils/googleAnalitycs'
import { getParameter } from '../../utils/queryString'
interface variablesFetchProps {
  storeId: string
  from: string | undefined
  to: string | undefined
  paymentType: string | undefined
  userId: string | undefined
  minPrice: number | undefined
  maxPrice: number | undefined
}

export const fillRangeDatesWithNoDataForDownloadFile = (
  today?: string,
  nextDate?: string,
  offset = 0,
  shouldStartWithFisrtDate = false
): ListItems[] => {
  if (!today || !nextDate) return []
  const res: ListItems[] = []
  const diff = moment(today).diff(nextDate, 'days')
  if (diff < 1) return []
  for (let index = 0; index < diff + offset; index++) {
    const subtractFirstDate = shouldStartWithFisrtDate ? 0 : 1
    const date = moment(today)
      .subtract(index + subtractFirstDate, 'days')
      .toJSON()
    res.push({
      date,
      ticketId: '--',
      paymentType: '--',
      totalAmount: 0,
      time: '--',
      products: '--',
      operations: '0',
      posId: '-',
      userId: ''
    })
  }
  return res
}

export const fillDatesWithNoDataForDownloadFile = (
  queryData: ReportDownloadFileByMonthDaysType
): ReportDownloadFileByMonthDaysType => {
  const { ListItems } = queryData.getTotalSaleByDateReport

  const ListItemsWithNoData: ListItems[] = []

  const fillItemsWithNoDataAtInit = () => {
    const today = getParameter('to')
    const { date: nextDate } = ListItems[0]

    const itemsWithNoData = fillRangeDatesWithNoDataForDownloadFile(
      today,
      nextDate,
      0,
      true
    )
    ListItemsWithNoData.push(...itemsWithNoData)
  }

  const fillItemsWithNoDataAtMiddle = () => {
    ListItems.forEach((item, index) => {
      if (index === ListItems.length - 1) {
        ListItemsWithNoData.push(item)
        return
      }
      ListItemsWithNoData.push(item)

      const { date: today } = item
      const { date: nextDate } = ListItems[index + 1]

      const itemsWithNoData = fillRangeDatesWithNoDataForDownloadFile(
        today,
        nextDate,
        -1
      )
      ListItemsWithNoData.push(...itemsWithNoData)
    })
  }

  const fillItemsWithNoDataAtEnd = () => {
    const today = getParameter('from')
    if (!today) {
      return
    }
    const todaySubs = moment(today).format('YYYY-MM-DD')
    const { date: nextDate } = ListItems[ListItems.length - 1]
    const [justDate] = nextDate.split('T')

    const itemsWithNoData = fillRangeDatesWithNoDataForDownloadFile(
      justDate,
      todaySubs,
      0,
      false
    )
    ListItemsWithNoData.push(...itemsWithNoData)
  }

  fillItemsWithNoDataAtInit()
  fillItemsWithNoDataAtMiddle()
  fillItemsWithNoDataAtEnd()

  return {
    getTotalSaleByDateReport: {
      ListItems: ListItemsWithNoData
    }
  }
}

export const createNameFromCollaborator = (
  collaborator: CollaboratorInterface
): string | undefined => {
  if (collaborator) {
    const { name, middleName, familyName } = collaborator
    return `${name} ${middleName} ${familyName}`
  }
  return undefined
}

export const replaceCollaboratorIdByName = (
  queryData: ReportDownloadFileByMonthDaysType,
  collaboratorsWithIdKey: { [key: string]: CollaboratorInterface },
  partnerId: string,
  partnerPosId: string
): ReportDownloadFileByMonthDaysType => {
  const ListItems = queryData.getTotalSaleByDateReport.ListItems.map((item) => {
    const { userId, posId } = item
    const keyUserId = userId === null ? partnerId : userId
    const correctPosId = posId === null ? partnerPosId : posId
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const collaborator = collaboratorsWithIdKey[keyUserId]
    const fullName = createNameFromCollaborator(collaborator)

    return { ...item, name: fullName, posId: correctPosId }
  })

  return {
    getTotalSaleByDateReport: {
      ListItems
    }
  }
}

export const filteredByPartnerId = (
  queryData: ReportDownloadFileByMonthDaysType,
  collaboratorId: string | null | undefined,
  partnerId: string
): ReportDownloadFileByMonthDaysType => {
  if (collaboratorId === partnerId) {
    return queryData
  }
  const ListItems = queryData.getTotalSaleByDateReport.ListItems.filter(
    ({ userId }) => userId === collaboratorId
  )

  return {
    getTotalSaleByDateReport: {
      ListItems
    }
  }
}

export const customEventGASalesFilter = (
  variables: variablesFetchProps
): void => {
  const { from, to, paymentType, userId, minPrice, maxPrice } = variables

  if (from || to) {
    customEventGA({
      eventAction: 'date_filter_sales',
      eventCategory: 'filters',
      eventLabel: 'sales_report'
    })
  }

  if (paymentType) {
    customEventGA({
      eventAction: 'paymentType_filter_sales',
      eventCategory: 'filters',
      eventLabel: 'sales_report'
    })
  }

  if (userId) {
    customEventGA({
      eventAction: 'user_filter_sales',
      eventCategory: 'filters',
      eventLabel: 'sales_report'
    })
  }

  if (minPrice || maxPrice) {
    customEventGA({
      eventAction: 'price_filter_sales',
      eventCategory: 'filters',
      eventLabel: 'sales_report'
    })
  }
}
