// Packages
import React, { useEffect, useRef, useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useReactToPrint } from 'react-to-print'
import { toast, ToastContainer } from 'react-toastify'
import { navigate } from '@reach/router'
import { useSelector } from 'react-redux'

// Components
import ReportContent from '../../components/ReportContent'
import { ReportByMonthDaysPDF } from '../../components/ReportByMonthDaysPDF'
import ModalResult from '../../components/Shared/ModalResult'
import ModalResponse from '../../components/Shared/ModalResponse'
import CustomButton from '../../components/Storybook/customButton'

// Assets
import {
  GET_MONTHS,
  GET_TOTAL_SALE_BY_DATE_REPORT
} from '../../graphql/queries/Reports'
import { getParameter } from '../../utils/queryString'

// Utils
import {
  createNameFromCollaborator,
  customEventGASalesFilter,
  fillDatesWithNoDataForDownloadFile,
  filteredByPartnerId,
  replaceCollaboratorIdByName
} from './utils'
import { transformQueryReportToDataComponents } from '../ReportContainerByMonths/utils'
import { callApiExportExcel } from '../../utils/apis'
import { setLocalStorageItem } from '../../utils/storage'
import { convertArrayToObj } from '../../utils/general'
import { customEventGA } from '../../utils/googleAnalitycs'

// Types
import UserTypes from '../../types/UserTypes'
import { ReportDownloadFileByMonthDaysType } from '../../types/Reports/ReportDownloadFileByMonthDays'
import { objInterface } from '../../types/General'

//Querys
import { GET_COLLABORATORS_AND_PARTNERS } from '../../graphql/queries/collaborators'
import { callProfileRedux } from '../ProfileContainer'

const ReportContainerByMonthDays: React.FC = () => {
  const [objectCollaborators, setObjectCollaborators] = useState<objInterface>(
    {}
  )
  const [typeDownload, setTypeDownload] = useState<'PDF' | 'CSV' | undefined>(
    undefined
  )
  const [typeModal, setTypeModal] = useState<'PDF' | 'CSV' | undefined>(
    undefined
  )
  const [dataReportToDownloadFile, setDataReportToDownloadFile] =
    useState<ReportDownloadFileByMonthDaysType | null>(null)
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false)
  const { user_data } = useSelector(callProfileRedux)
  const componentRef = useRef(null)
  const from = getParameter('from')
  const to = getParameter('to')
  const storeId = getParameter('storeId')
  const minPrice = getParameter('minPrice')
  const maxPrice = getParameter('maxPrice')
  const paymentType = getParameter('paymentType')
  const userId = getParameter('userId')
  const user: UserTypes.UserInformation = user_data.getProfile
  const store = user_data.getProfile?.stores.find(
    ({ storeID }: { storeID: string }) => storeID === storeId
  )

  from && setLocalStorageItem('monthDaysFrom', from)
  to && setLocalStorageItem('monthDaysTo', to)
  to && setLocalStorageItem('minPrice', minPrice ? minPrice : '')
  to && setLocalStorageItem('maxPrice', maxPrice ? maxPrice : '')
  to && setLocalStorageItem('paymentType', paymentType ? paymentType : '')
  to && setLocalStorageItem('userId', userId ? userId : '')

  let tileRow: any = {
    rowBody: [],
    rowHeader: { parameter: '', value: '' }
  }
  let tableGrid: any = {
    data: [],
    columns: [],
    isHeader: false
  }

  const [
    refetch,
    {
      data: dataStoreMonths,
      loading: loadingStoreMonths,
      error: errorStoreMonths
    }
  ] = useLazyQuery(GET_MONTHS, {
    context: {
      clientName: 'api-instore'
    },
    fetchPolicy: 'cache-and-network'
  })

  const [
    refetchFile,
    {
      data: dataStoreMonthsFile,
      loading: loadingStoreMonthsFile,
      error: errorStoreMonthsFile
    }
  ] = useLazyQuery(GET_TOTAL_SALE_BY_DATE_REPORT, {
    context: {
      clientName: 'api-instore'
    },
    fetchPolicy: 'cache-and-network'
  })

  const {
    data: dataStoreCollaborators,
    loading: loadingDataStoreCollaborators
  } = useQuery(GET_COLLABORATORS_AND_PARTNERS)

  const onClickDonwload = (): void => {
    const variables = {
      storeId,
      from,
      to,
      paymentType,
      userId,
      minPrice: minPrice ? parseFloat(minPrice) : undefined,
      maxPrice: maxPrice ? parseFloat(maxPrice) : undefined
    }
    refetchFile({ variables })
    setTypeDownload(typeModal)
    setIsVisibleModal(false)
    setTypeModal(undefined)
  }

  const onClickDonwloadCSV = async (): Promise<void> => {
    callApiExportExcel({
      content: () => componentRef.current,
      dateRange: {
        from,
        to
      }
    })
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const handleModal = (type: 'PDF' | 'CSV'): void => {
    customEventGA({
      eventCategory: 'Reports',
      eventAction: 'Download Sales Report ' + type,
      eventLabel: 'ReportContainerByMonthDays'
    })

    setTypeDownload(undefined)
    setIsVisibleModal(true)
    setTypeModal(type)
  }

  const getFirstDeviceIdFromThisStoreId = () => {
    const thisStore = user_data.getProfile.stores.find(
      ({ storeID }: { storeID: string }) => storeID === storeId
    )
    const device = thisStore.device as any[] | null
    return device ? device[0].deviceID : ''
  }

  if (dataStoreMonths && !loadingStoreMonths && !errorStoreMonths && storeId) {
    const isFilterValue =
      minPrice || maxPrice || userId || paymentType ? true : false
    const { tileRow: tileRowTransformed, tableGrid: tableGridTransformed } =
      transformQueryReportToDataComponents(
        dataStoreMonths,
        'month',
        storeId,
        isFilterValue
      )
    tileRow = tileRowTransformed
    tableGrid = tableGridTransformed
  }

  useEffect(() => {
    if (dataStoreCollaborators && !loadingDataStoreCollaborators) {
      const collaboratorById = convertArrayToObj(
        dataStoreCollaborators.getCollaboratorsAndPartnerData,
        'userID'
      )
      setObjectCollaborators(collaboratorById)
    }
  }, [dataStoreCollaborators, loadingDataStoreCollaborators])

  useEffect(() => {
    if (
      dataStoreMonthsFile &&
      !loadingStoreMonthsFile &&
      !errorStoreMonthsFile &&
      typeDownload
    ) {
      let dataQuery = dataStoreMonthsFile
      const withNoFilter = !minPrice && !maxPrice && !paymentType && !userId

      if (userId) {
        dataQuery = filteredByPartnerId(dataStoreMonthsFile, userId, user.sub)
      }

      const dataStoreMonthsFileWithNameReplaced = replaceCollaboratorIdByName(
        dataQuery,
        objectCollaborators,
        user.sub,
        getFirstDeviceIdFromThisStoreId()
      )

      const dataReportDownload = withNoFilter
        ? fillDatesWithNoDataForDownloadFile(
            dataStoreMonthsFileWithNameReplaced
          )
        : dataStoreMonthsFileWithNameReplaced

      setDataReportToDownloadFile(dataReportDownload)

      setTimeout(() => {
        if (typeDownload === 'CSV') {
          onClickDonwloadCSV()
        }
        if (typeDownload === 'PDF') {
          handlePrint()
        }
        setTypeDownload(undefined)
      }, 10)
    }
  }, [dataStoreMonthsFile, loadingStoreMonthsFile])

  useEffect(() => {
    if (storeId) {
      const variables = {
        storeId,
        from,
        to,
        paymentType,
        userId,
        minPrice: minPrice ? parseFloat(minPrice) : undefined,
        maxPrice: maxPrice ? parseFloat(maxPrice) : undefined
      }
      customEventGASalesFilter(variables)
      refetch({
        variables
      })
    }
  }, [from, to, storeId, minPrice, maxPrice, paymentType, userId])

  useEffect(() => {
    if (errorStoreMonths) {
      toast.error(
        'Hubo un problema al consultar la información, intenta nuevamente',
        {
          toastId: 'error-filters'
        }
      )
      setTimeout(() => {
        navigate(`/reports`)
      }, 3000)
    }
  }, [errorStoreMonths])

  const modalContent = (
    <ModalResult
      key="cancel-edit-modal"
      status={'warning'}
      subTitle={`¿Deseas continuar con la descarga del archivo ${
        typeModal === 'PDF' ? typeModal : 'Excel'
      }?`}
      extra={
        <>
          <CustomButton
            id="success-sales-report-download-file"
            name="successSalesReportDownloadFile"
            dataTestId="data-testid-success-sales-report-download-file"
            label="Aceptar"
            className="save-button-sales-report-download-file"
            onClick={onClickDonwload}
          />
          <CustomButton
            id="cancel-sales-report-download-file"
            name="cancelSalesReportDownloadFile"
            dataTestId="data-testid-cancel-sales-report-download-file"
            label="Cancelar"
            className="cancel-button-sales-report-download-file"
            onClick={() => setIsVisibleModal(false)}
          />
        </>
      }
    />
  )

  return (
    <>
      <div style={{ display: 'none' }}>
        <ReportByMonthDaysPDF
          ref={componentRef}
          queryData={dataReportToDownloadFile}
          storeInformation={{
            storeID: storeId,
            partnerName:
              user?.name + ' ' + user?.familyName + ' ' + user?.middleName,
            storeName: store?.name
          }}
          from={from}
          to={to}
          minPrice={minPrice}
          maxPrice={maxPrice}
          paymentType={paymentType}
          collaborator={createNameFromCollaborator(
            objectCollaborators[userId || '']
          )}
        />
      </div>
      <ReportContent
        tileRow={tileRow}
        tableGrid={tableGrid}
        loading={loadingStoreMonths}
        view="month"
        onClickDonwloadPDF={() => handleModal('PDF')}
        onClickDonwloadCSV={() => handleModal('CSV')}
        loadingDonwload={loadingStoreMonths}
        disabledDownload={!dataStoreMonths?.generalDetail.detailSummary.length}
        storeID={storeId ? storeId : user.stores[0].storeID}
      />
      <ModalResponse
        isModalVisible={isVisibleModal}
        content={modalContent}
        onCancel={() => {
          setIsVisibleModal(false)
        }}
      />
      <ToastContainer
        autoClose={2500}
        position="top-center"
        theme="colored"
        toastClassName={'toast-container-custom'}
      />
    </>
  )
}

export default ReportContainerByMonthDays
