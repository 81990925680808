import styled from 'styled-components'

interface StyledProps {
  styles: StyledTextRedProps
}
interface StyledTextRedProps {
  isred?: boolean
}

export const StyledConatnerReportByMonthsPDF = styled.div`
  font-size: 10px;

  padding: 15px;
`

export const StyledContainerTable = styled.div`
  padding: 15px;
`

export const StyledContainerTableDetail = styled.div`
  padding: 20px 15px;
`

export const StyledTHDetail = styled.th<StyledProps>`
  color: ${(props) => (props.styles.isred ? 'red' : 'black')};
  padding: 5px 2px;
  border: 1px solid black;
  font-weight: normal;
`

export const StyledTableFilters = styled.div`
  padding: 5px 15px;
  width: fit-content;
`

export const StyledBlueText = styled.td`
  color: #002060;
  font-weight: bold;
  padding: 2px 5px;
`
export const StyledGrayText = styled.td`
  color: 'gray !important';
  padding: 2px 5px;
`
