import React from 'react'
import WithPrivateRoute from '../../../../higherOrderComponents/WithPrivateRoute'
import SearchEngineOptimization from '../../../../components/SEO'
import ReportContainerByMonthDays from '../../../../containers/ReportContainerByMonthDays'
import LayoutMain from '../../../../layout'

const DetailsByDay: React.FC = () => {
  return (
    <LayoutMain>
      <SearchEngineOptimization title="Detalle por día" />
      <WithPrivateRoute component={ReportContainerByMonthDays} />
    </LayoutMain>
  )
}

export default DetailsByDay
