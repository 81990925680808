import { gql } from '@apollo/client'

export const GET_PERMISSIONS_COLLABORATOR = gql`
  query getPermissions {
    getPermissions {
      appModule
      nameModule
      permissions {
        appModuleAction
        isActive
        grantedAccess
        descriptionModule
      }
    }
  }
`

export const GET_COLLABORATORS_AND_PARTNERS = gql`
  {
    getCollaboratorsAndPartnerData {
      partnerID
      userID
      name
      middleName
      familyName
      roleID
      roleDescription
    }
  }
`
